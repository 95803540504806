.container{
    color: aliceblue;
    width: 98%;
    display: flex;
    height: 10vh;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: 500;
    position: fixed;
    top: 0;
    left: 0;
}
.space{
    width: 30%;
    display: flex;
    justify-content: space-around;
}