.container{
    color: aliceblue;
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    z-index: 20;
}
.left{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: -1% 3%;
    letter-spacing: 2px;
    position: relative;
}
.right{
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}
.mainHeader{
    font-size: 140px;
    -webkit-animation: type 1s steps(90, end), 
    blink .25s step-end infinite;;
    animation: type 1s steps(90, end),
    blink .25s step-end infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    width: 100%;
    overflow: hidden;
}
.subName{
        font-size: 120px;
            -webkit-animation: type2 1s steps(90, end),
        blink .25s step-end infinite;
        animation: type2 1s steps(90, end),
        blink .25s step-end infinite;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        opacity: 0;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
}

@keyframes type {
    0% {
      width: 0;
    }
    0.9% {
      border-right: .15em solid orange;
    }
    100% {
      border: none;
    }
  }
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    0.9% {
      border-right: .15em solid orange;
    }
    100% {
      border: none;
    }
  }

  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    1.9% {
      border-right: .15em solid orange;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    1.9% {
      border-right: .15em solid orange;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }

  .glitch{
    background-image: url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif);
	background-size: cover;
	color: transparent;
	-moz-background-clip: text;
	-webkit-background-clip: text;
    background-clip: text;
    margin-left: 1%;
    font-weight: 300;
  }
  .verySub{
      font-size: 22px;
      width: 100%;
      position: absolute;
      left: 1%;
      bottom: 5%;
    
  }
  .image{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      display: flex;
      justify-content: center;
      transform: translate(-50%,-50%);
  }
  .img{
      background-color: rgb(243, 243, 243);
      height: 60%;
  }