.container{
     display: flex;
    flex-direction: column;
    width: 100vw;
   height:100vh;
  
   color: aliceblue;
   position: relative;
   align-items: center;
}
.top{
    display: flex;
    flex-direction: row;
   font-size: 180px;
    width: 100%;
    justify-content: flex-end;
    
}
.row{
    display: flex;
    height: 45vh;
    width: 100%;
    padding: 2%;
}
.paper{
    background-color: rgba(12, 12, 12, 0.925);
    border-radius: 20px;
    width: 80%;
    
}
.verySub{
    width: 50%;
    font-size: 30px;
    height: 100%;
    display: flex;
    font-weight: 300;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgb(214, 175, 124);
}
.right{

    width: 50%;
    display: flex;
    justify-content: center;
}
.img{
    height: 100%;
    z-index: 2999;
    background-color: aliceblue;
    border-radius: 100%;
}
.absoluteDiv{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
}