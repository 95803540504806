
.container{
    background-color: #000000;
    height: 100vh;
    width: 100vw;
      font-family:'Poppins';
    font-weight: 100;
    overflow: scroll;
    position: relative;
    display: flex;
    flex-direction: column;
}
.container::-webkit-scrollbar{
  height: 0;
}
/* .container::before {
  content:"";
  position: absolute;
  width:200vw;
  height: 200vh;
  left: -50%;
  top: -50%;
  background-image: url("./grain.png");
  animation:grain 1.5s steps(6) infinite;
  opacity: 0.6;
} */
@keyframes grain {
  0%, 100% {transform: translate(0,0);}
  10% {transform: translate(-5%,-10%);}
  30% {transform: translate(3%,-15%);}
  50% {transform: translate(12%,9%);}
  70% {transform: translate(9%,4%);}
  90% {transform: translate(-1%,7%);}
}
